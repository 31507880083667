import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Box,
  Rating,
  Avatar,
  Container,
  IconButton,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Divider,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Chip,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  DialogContent,
} from '@mui/material';
import {
  Share,
  ShareOutlined,
  BookmarkBorder,
  BookmarkAddOutlined,
  Search,
  ArrowForward,
  Lock,
  LockOutlined,
  Warning,
  ErrorOutlineOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  ArrowForwardIos,
  MoreVert,
  OutlinedFlag,
  HelpOutlineOutlined,
  SearchOutlined,
} from '@mui/icons-material';
import { styled } from '@mui/system';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import ShareIcon from '@mui/icons-material/Share';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import MovieIcon from '@mui/icons-material/Movie';
import BookIcon from '@mui/icons-material/Book';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import AppsIcon from '@mui/icons-material/Apps';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import StarIcon from '@mui/icons-material/Star';
import image1 from './images/image1_s.png';
import image2 from './images/image2_s.png';
import image3 from './images/image3_s.png';
import image4 from './images/image4_s.png';
import image5 from './images/image5_s.png';
import video from './images/video.mp4';
import logo from './images/logo.png';
import googlePlay from './images/google_pay.png';
import iconUser from './images/icon_user.png';
import iconTip from './images/icon_tip.png';
import otherImg1 from './images/otherImage1.png';
import ins from './images/ins.png';
import tt from './images/tt.png';
import google from './images/google.png';
import fb from './images/fb.png';
import player from './images/player.png';
// import iconWarring from './images/icon_warring.png';
// import iconCollect from './images/icon_collect.png';
// import iconDel from './images/icon_del.png';

// 顶部导航栏样式
const Header = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#000',
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const AppHeader = () => {
  return (
    <Header position="sticky">
      {/* <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          App Name
        </Typography>
        <Button variant="contained" startIcon={<InstallMobileIcon />}>
          Install
        </Button>
        <IconButton color="primary">
          <ShareIcon />
        </IconButton>
      </Toolbar> */}
      {/* Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2, mb: 2, padding: '0 24px' }}>
        <Box display="flex" alignItems="center">
          <Avatar src={googlePlay} alt="Google Play" />
          <Typography variant="h6" sx={{ marginLeft: '8px', fontWeight: 'bold' }}>
            Google Play
          </Typography>
        </Box>
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <SearchOutlined fontSize="small" sx={{ color: '#666666', width: '24px', height: '24px', mr: 1 }} />
          <HelpOutlineOutlined fontSize="small" sx={{ color: '#666666', width: '24px', height: '24px', mr: 1 }} />
          <IconButton sx={{ width: '32px', height: '32px' }}>
            <img src={iconUser}></img>
          </IconButton>
        </Box>
      </Box>
    </Header>
  );
};

// 应用信息
const AppInfo = ({ handTrackingClick, catLinkUrl }) => {
  return (
    <Box
      sx={{
        padding: '16px 0',
        margin: '0 auto',
        backgroundColor: '#fff',
      }}
    >
      {/* App Info */}
      <Box display="flex" sx={{ mt: 5, mb: 3 }}>
        <Avatar variant="rounded" src={logo} alt="TanChat" sx={{ width: 72, height: 72, marginRight: '16px' }} />
        <Box>
          <Typography variant="h5" fontWeight="bold">
            TanChat
          </Typography>
          <Typography sx={{ color: '#3A8562', fontWeight: '500' }}>Bu sadece buluşmayla ilgili değil.</Typography>
        </Box>
      </Box>

      {/* Rating and Stats */}
      <Grid container justifyContent="space-between" alignItems="center" spacing={2} mb={2}>
        <Grid item xs={4} textAlign="center">
          <Typography sx={{ borderRight: '1px solid #e0e0e0' }}>
            4.9<i style={{ fontSize: '12px' }}>★</i>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            64K yorumlar
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <Typography sx={{ borderRight: '1px solid #e0e0e0' }}>10M+</Typography>
          <Typography variant="body2" color="textSecondary">
            indiriliyor
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <Typography>
            <span style={{ border: '1px solid #000000' }}>12+</span>
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ display: 'inline-flex', alignItems: 'center' }}>
            Rated for 12+
            <ErrorOutlineOutlined fontSize="small" sx={{ width: '12px', height: '12px', ml: 0.5 }} />
          </Typography>
        </Grid>
      </Grid>

      {/* Action Button */}
      <Button
        variant="contained"
        color="success"
        fullWidth
        href={catLinkUrl}
        // onClick={handTrackingClick}
        sx={{
          background: '#3A8562',
          borderRadius: '8',
          fontWeight: '500',
          textTransform: 'none',
        }}
      >
        montaj
      </Button>

      {/* Additional Options */}
      <Box display="flex" justifyContent={'center'} sx={{ mt: 2, fontWeight: '500' }}>
        <Button startIcon={<Share />} sx={{ color: '#3A8562', textTransform: 'none' }}>
          Share
        </Button>
        <Button startIcon={<BookmarkAddOutlined />} sx={{ color: '#3A8562', textTransform: 'none' }}>
          Add to wishlist
        </Button>
      </Box>
    </Box>
  );
};

// 应用截图轮播样式
const Screenshot = styled(CardMedia)({
  height: 200,
  borderRadius: 8,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
});

const screenshots = [
  {
    text: '',
    src: image1,
  },
  {
    text: '',
    src: image2,
  },
  { text: '', src: image3 },
  { text: '', src: image4 },
  { text: '', src: image5 },
];
const AppScreenshots = () => {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  // 打开图片对话框
  const handleClickOpen = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  // 关闭图片对话框
  const handleClose = () => {
    setOpen(false);
  };

  // 切换到上一张图片
  const handlePrev = () => {
    setSelectedIndex((prevIndex) => (prevIndex === 0 ? screenshots.length - 1 : prevIndex - 1));
  };

  // 切换到下一张图片
  const handleNext = () => {
    setSelectedIndex((prevIndex) => (prevIndex === screenshots.length - 1 ? 0 : prevIndex + 1));
  };
  return (
    <>
      <Box sx={{ overflowX: 'auto', display: 'flex', gap: 2, py: 2 }}>
        {screenshots.map((image, index) => (
          <Box
            key={index}
            onClick={() => handleClickOpen(index)}
            sx={{
              minWidth: '115px',
              flex: '0 0 auto',
              boxShadow: '0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15)',
              borderRadius: '8px',
            }}
          >
            <Screenshot image={image.src} sx={{ width: '100%' }} />
          </Box>
        ))}
      </Box>
      {/* 图片放大和切换部分 */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent sx={{ position: 'relative', textAlign: 'center', padding: '0' }}>
          {/* 左右切换按钮 */}
          <IconButton
            onClick={handlePrev}
            sx={{
              background: '#fff !important',
              position: 'absolute',
              top: '50%',
              left: 10,
              transform: 'translateY(-50%)',
              zIndex: 1,
            }}
          >
            <ArrowForwardIosIcon sx={{ transform: 'rotate(180deg)' }} />
          </IconButton>

          <img
            src={screenshots[selectedIndex].src}
            style={{
              width: '100%',
              height: 'auto',
              display: 'block',
              maxHeight: '100vh', // 限制高度，防止图片太大
              objectFit: 'contain', // 确保图片按比例缩放并且不会拉伸
            }}
          />

          <IconButton
            onClick={handleNext}
            sx={{
              background: '#fff !important',
              position: 'absolute',
              top: '50%',
              right: 10,
              transform: 'translateY(-50%)',
              zIndex: 1,
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

// 应用介绍
const AppDescription = () => {
  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ mt: 2, mb: 2 }}>
        Bu başvuru hakkında
        <IconButton sx={{ float: 'right' }}>
          <ArrowForward />
        </IconButton>
      </Typography>
      <Typography variant="body1" paragraph>
        Burada bir sürü heteroseksüel arkadaş var. Arkadaşın tanımı bir yatakta çıplak yatan ama seks yapmayan iki
        kişidir. Bunu yapabilir misin?
      </Typography>
    </>
  );
};

const AppDataSafety = () => {
  return (
    <Box>
      <Typography variant="subtitle1" fontWeight="bold">
        Veri Güvenliği
      </Typography>
      <Typography color="textSecondary" paragraph>
        Güvenlik, geliştiricilerin verilerinizi nasıl topladığını ve paylaştığını anlamakla başlar. Veri gizliliği ve
        güvenliği uygulamaları kullanımıza, bölgenize ve yaşınıza bağlı olarak değişebilir.
      </Typography>
      <Box sx={{ padding: '16px', border: '1px solid #CBCBCB', borderRadius: 2 }}>
        <Box display="flex" alignItems="top" mb={1}>
          <ShareOutlined fontSize="small" />
          <Typography sx={{ marginLeft: '8px' }}>
            Bu uygulama bu tür verileri üçüncü taraflarla paylaşabilir: Konum bilgileri, kişisel bilgiler ve diğer 2
            kişi
          </Typography>
        </Box>
        <Box display="flex" alignItems="top" mb={1}>
          <CloudUploadOutlined fontSize="small" />
          <Typography sx={{ marginLeft: '8px' }}>
            Bu uygulama şu tür verileri toplayabilir: Konum bilgileri, kişisel bilgiler ve diğer 3 kişi
          </Typography>
        </Box>
        <Box display="flex" alignItems="top" mb={1}>
          <LockOutlined fontSize="small" />
          <Typography sx={{ marginLeft: '8px' }}>Veriler şifrelenmemiş</Typography>
        </Box>
        <Box display="flex" alignItems="top">
          <DeleteOutlined fontSize="small" />
          <Typography sx={{ marginLeft: '8px' }}>Veriler silinemez</Typography>
        </Box>
      </Box>
    </Box>
  );
};

// 评分进度条
const RatingBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

// 应用标题及评分样式
const TitleSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

// 评论容器
const ReviewCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
const AppReviewCard = () => {
  return (
    <>
      {/* Tabs for device types */}
      <Box mt={2} display="flex" justifyContent="normal" gap={4}>
        {[
          {
            text: 'Phone',
            icon: <PhoneAndroidIcon sx={{ mr: 0.5, fontSize: '16px', verticalAlign: 'middle' }} />,
            sx: { background: '#E8F3EF', border: 'none', color: '#3A8562' },
          },
          {
            text: 'Chromebook',
            icon: <LaptopChromebookIcon sx={{ mr: 0.5, fontSize: '16px', verticalAlign: 'middle' }} />,
            sx: {},
          },
          {
            text: 'Tablet',
            icon: <TabletAndroidIcon sx={{ mr: 0.5, fontSize: '16px', verticalAlign: 'middle' }} />,
            sx: {},
          },
        ].map((item) => (
          <Chip
            key={item.text}
            label={
              <>
                {item.icon}
                {item.text}
              </>
            }
            variant="outlined"
            sx={item.sx}
            onClick={() => {}}
          />
        ))}
      </Box>

      {/* 总评分 */}
      <Grid container spacing={2} alignItems="normal" sx={{ mt: 2 }}>
        <Grid item xs={4}>
          <Typography variant="h2" fontWeight="bold">
            4.9
          </Typography>
          <Rating value={4.9} readOnly precision={0.1} sx={{ color: '#3A8562' }} size="small" />
          <Typography sx={{ mt: 1, fontSize: 12, color: 'gray' }}>60.9K yorumlar</Typography>
        </Grid>

        {/* 评分分布条 */}
        <Grid item xs={8}>
          {[5, 4, 3, 2, 1].map((star, index) => (
            <Box key={index} display="flex" alignItems="center" mb={0} sx={{ height: 20 }}>
              <Typography>{star}</Typography>
              <LinearProgress
                variant="determinate"
                value={star === 5 ? 95 : star === 4 ? 4 : star === 3 ? 1 : 0.5}
                sx={{
                  flexGrow: 1,
                  height: 10,
                  mx: 1,
                  backgroundColor: '#E8EAED',
                  borderRadius: '4px',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#3A8562',
                  },
                }}
              />
            </Box>
          ))}
        </Grid>
      </Grid>

      <Box
        sx={{
          padding: '16px 0',
          margin: '0 auto',
          backgroundColor: '#fff',
          borderRadius: '8px',
        }}
      >
        {/* 用户信息部分 */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display="flex" alignItems="center">
            <Avatar sx={{ bgcolor: '#e0e0e0', width: 48, height: 48 }}>S</Avatar>
            <Box ml={2}>
              <Typography variant="h6">Sarah</Typography>
            </Box>
          </Box>

          {/* 三个点的图标 */}
          <IconButton>
            <MoreVert />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <Rating value={5} readOnly size="small" sx={{ fontSize: '12px', color: '#3A8562' }} />
          <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
            13 Ekim 2024
          </Typography>
        </Box>

        {/* 评论内容 */}
        <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
          💖 TanChat uygulamasını gerçekten çok seviyorum! 🎉
          <br />
          🎈 TanChat, dünyanın her yerinden ilginç ruhları birbirine bağlayan inanılmaz bir sosyal köprü gibidir. Burada
          sohbet etmek ve buluşmak çok kolay ve keyifli hale geldi 😜.
          <br />
          🗨 Sohbet işlevi çok güçlü, arayüz tasarımı basit ve güzel, işlem özellikle uygun, böylece başkalarıyla
          herhangi bir engel olmadan iletişim kurabiliyorum.
          <br />
          🎁 Ayrıca TanChat, her zaman taze ve canlı tutmak için sürekli yeni özellikler ve etkinlikler geliştiriyor 🎉.
          <br />
          👫 Ayrıca TanChat aracılığıyla tarih planlaması çok tatlı... Burada bir sürü harika insanla tanıştım ve çok
          güzel zamanlar yaşadım ✨.
          <br />
          🎯 Ayrıca TanChat, tüm uygulamayı her zaman taze ve canlı tutmak için sürekli yeni özellikler ve etkinlikler
          geliştiriyor 💥. Daha fazlası için geri gelmemi sağlayan büyülü bir sosyal oyun alanı gibi 🥰.
        </Typography>

        {/* 反馈部分 */}
        <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mb: 2 }}>
          16 Kişi bu yorumu yararlı buldu
        </Typography>

        {/* Yes 和 No 按钮 */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>Bunu yararlı buldunuz mu?</Typography>
          <Box>
            <Button
              variant="outlined"
              color="success"
              sx={{
                color: '#3A8562',
                mr: 1,
                borderRadius: '999px',
                textTransform: 'none',
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={{
                border: '1px solid #CBCBCB',
                color: '#999999',
                borderRadius: '999px',
                textTransform: 'none',
              }}
            >
              NO
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const AppSupport = () => {
  return (
    <Container sx={{ my: 4 }}>
      {/* 应用支持信息 */}
      <Typography variant="h5" gutterBottom>
        App Support
      </Typography>
      <Typography variant="body1">This app is compatible with your device.</Typography>
      <Box mt={2}>
        <Typography variant="subtitle1">Requires Android:</Typography>
        <Typography variant="body2">5.0 and up</Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="subtitle1">In-app Purchases:</Typography>
        <Typography variant="body2">Yes</Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="subtitle1">Developer:</Typography>
        <Typography variant="body2">ABC Studios</Typography>
      </Box>
      <Box mt={2}>
        <Button variant="contained" color="primary" startIcon={<InstallMobileIcon />}>
          Install Now
        </Button>
      </Box>
      <Divider sx={{ my: 4 }} />
    </Container>
  );
};

// 模拟的类似应用程序数据
const similarApps = [
  { name: 'Toilet Rush Race', desc: 'Rocket Succeed', rating: 3.9, icon: ins },
  { name: 'Fake Phone Call', desc: '', rating: 4.8, icon: google },
  { name: 'Stick Hero: Tower Defense', desc: '', rating: 4.8, icon: tt },
  { name: 'Soccer Game', rating: 4.6, desc: '', icon: fb },
  { name: 'AR Drawing Sketchbook', desc: '', rating: 4.6, icon: player },
];

const AppFooter = () => {
  return (
    <Box sx={{ padding: '16px 0', backgroundColor: '#fff' }}>
      {/* Uygulama Desteği */}
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        Uygulama Desteği
        <IconButton sx={{ float: 'right' }}>
          <ArrowForwardIos sx={{ transform: 'rotate(90deg)' }} />
        </IconButton>
      </Typography>

      {/* Benzer Uygulamalar */}
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        Benzer uygulamalar
        <IconButton sx={{ float: 'right' }}>
          <ArrowForward />
        </IconButton>
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {similarApps.map((app, index) => (
          <Grid key={index} item xs={6} sm={4} md={2}>
            <Box display="flex" alignItems="center" sx={{ overflow: 'hidden' }}>
              <Avatar
                variant="rounded"
                src={app.icon}
                alt={app.name}
                sx={{
                  width: 56,
                  height: 56,
                  mr: 2,
                  borderRadius: '10px',
                  boxShadow: '0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15)',
                }}
              />
              <Box>
                <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                  {app.name}
                </Typography>
                <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                  {app.desc}
                </Typography>
                <Typography variant="body2">
                  {app.rating} <i style={{ fontSize: '10px' }}>★</i>
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Typography fontWeight="bold" sx={{ mt: 2, mb: 2 }}>
        <IconButton sx={{ padding: 0, mr: 2 }}>
          <OutlinedFlag />
        </IconButton>
        Flag as inappropriate
      </Typography>

      {/* Footer Links */}
      <Box sx={{ mt: 4, mb: 4, color: '#666666' }}>
        <Box sx={{ fontWeight: 'bold' }}>Google Play</Box>
        <Box sx={{ mt: 1, mb: 1 }}>Play Pass</Box>
        <Box sx={{ mt: 1, mb: 1 }}>Play Points</Box>
        <Box sx={{ mt: 1, mb: 1 }}>Gift cards</Box>
        <Box sx={{ mt: 1, mb: 1 }}>Redeem</Box>
        <Box sx={{ mt: 1, mb: 1 }}>Refund policy</Box>
      </Box>
      <Box sx={{ mt: 4, mb: 4, color: '#666666' }}>
        <Box sx={{ fontWeight: 'bold' }}>Kids & family</Box>
        <Box sx={{ mt: 1, mb: 1 }}>Parent Guide</Box>
        <Box sx={{ mt: 1, mb: 1 }}>Family sharing</Box>
      </Box>

      <Grid container spacing={1} sx={{ mt: 4, mb: 2, fontSize: '14px' }}>
        <Grid item xs={4} sm={3}>
          <div>Terms of Service</div>
        </Grid>
        <Grid item xs={4} sm={3}>
          <div>Privacy</div>
        </Grid>
        <Grid item xs={4} sm={3}>
          <div>Abount Google Play</div>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ fontSize: '14px' }}>
        <Grid item xs={4} sm={3}>
          <div>Developers</div>
        </Grid>
        <Grid item xs={4} sm={3}>
          <div>Google Store</div>
        </Grid>
        <Grid item xs={4} sm={3}>
          <div>All prices include GST</div>
        </Grid>
      </Grid>
    </Box>
  );
};

const AppBottomNav = () => {
  const [value, setValue] = useState(1);
  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
    >
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        sx={{ boxShadow: '0px -1px 5px rgba(0,0,0,0.1)' }}
      >
        <BottomNavigationAction
          label="Games"
          icon={<SportsEsportsIcon />}
          sx={{
            '&.Mui-selected': {
              color: '#3A8562', // 选中状态下的颜色
            },
          }}
        />
        <BottomNavigationAction
          label="Apps"
          sx={{
            '&.Mui-selected': {
              color: '#3A8562', // 选中状态下的颜色
            },
          }}
          icon={<AppsIcon />}
        />
        <BottomNavigationAction
          label="Movies"
          icon={<MovieIcon />}
          sx={{
            '&.Mui-selected': {
              color: '#3A8562', // 选中状态下的颜色
            },
          }}
        />
        <BottomNavigationAction
          label="Books"
          icon={<MenuBookIcon />}
          sx={{
            '&.Mui-selected': {
              color: '#3A8562', // 选中状态下的颜色
            },
          }}
        />
        <BottomNavigationAction
          label="Kids"
          icon={<StarIcon />}
          sx={{
            '&.Mui-selected': {
              color: '#3A8562', // 选中状态下的颜色
            },
          }}
        />
      </BottomNavigation>
    </Box>
  );
};

// build a new URL with parameters p0-p6, fbclid and fbpaid
function buildURL(p0, p1, p2, p3, p4, p5, p6, fbclid, fbpid) {
  if (!p0) {
    return null;
  }
  let campaign, adgroup, creative;
  const tracker_token = p0;
  if (p1 || p2) {
    campaign = p1 + '(' + p2 + ')';
  } else {
    campaign = '';
  }
  if (p3 || p4) {
    adgroup = p3 + '(' + p4 + ')';
  } else {
    adgroup = '';
  }
  if (p5 || p6) {
    creative = p5 + '(' + p6 + ')';
  } else {
    creative = '';
  }
  if (!fbclid) {
    fbclid = '';
  }
  if (!fbpid) {
    fbpid = '';
  }
  // build a tracker URL
  let params = { campaign, adgroup, creative, fbclid, fbpid };
  let newURL =
    'https://app.adjust.com/' +
    tracker_token +
    '?' +
    Object.keys(params)
      .map((key) => key + '=' + encodeURIComponent(params[key]))
      .join('&');
  return newURL;
}
//get fbpid from Cookie written by Facebook Pixel
function getFbPid() {
  let fbPid = document.cookie.match(/(^|;) ?_fbp=([^;]*)(;|$)/);
  if (fbPid) {
    return fbPid[2];
  } else {
    return null;
  }
}

const AppPage = () => {
  // 调用 Adjust 追踪事件，将 fbclid 参数传递给 Adjust
  const fbclid = getFbclid('fbclid');
  const fbpid = getFbclid('fbpid') || getFbPid();
  const p0 = getFbclid('p0');
  const p1 = getFbclid('p1');
  const p2 = getFbclid('p2');
  const p3 = getFbclid('p3');
  const p4 = getFbclid('p4');
  const p5 = getFbclid('p5');
  const p6 = getFbclid('p6');
  const url = buildURL(p0, p1, p2, p3, p4, p5, p6, fbclid, fbpid);
  if (fbclid) {
    fbq('trackCustom', 'FBCLID_Captured', {
      fbclid: fbclid,
    });
  }
  if (fbpid) {
    fbq('trackCustom', 'FBPID_Captured', {
      fbpid: fbpid,
    });
  }
  const fb_pixel_id = '1427111701333857';
  const fb_access_token =
    'EAAFn9exb7PUBO2GR7Fq1f1rqqd4cDw0RySEY9oGZBZArQH2Sg3xfZBezUslCZByyfAgb7SqmreW9WDWJzy5PxcEZB5Y1H1MVZBlJJfsZCVrZAFCkqQpW7hZANJRJrv5K117kSGTm60amrDTVlagPBa4jc9U08Mpja6u2YDysct5jyOg7HJTgNOXTZBN1DmvfeP54GL8QZDZD';
  // 触发标准CTA事件的链接示例
  let catLinkUrl = url
    ? `${url}&fb_pixel_id=${fb_pixel_id}&fb_access_token=${fb_access_token}&redirect=https%3A%2F%2Fsupertochance.com%2Fdownloads%2F20241021-1548-TanChat-product-release.apk`
    : `https://app.adjust.com/1hfc2evv?campaign=${p1}%20%28${p2}%29&adgroup=${p3}%20%28${p4}%29&creative=${p5}%20%28${p6}%29&fbclid=${fbclid}&fb_pixel_id=${fb_pixel_id}&fb_access_token=${fb_access_token}&redirect=https%3A%2F%2Fsupertochance.com%2Fdownloads%2F20241021-1548-TanChat-product-release.apk`;
  // let catLinkUrl = `https://app.adjust.com/${p0}?campaign=${p1}%20%28${p2}%29&adgroup=${p3}%20%28${p4}%29&creative=${p5}%20%28${p6}%29&fbclid=${fbclid}&fb_pixel_id=${fb_pixel_id}&fb_access_token=${fb_access_token}&redirect=https%3A%2F%2Fsupertochance.com%2Fdownloads%2F20241021-1548-TanChat-product-release.apk`;
  // if (fbclid && fbpid) {
  //   catLinkUrl = `https://app.adjust.com/${p0}?campaign=${p1}%20%28${p2}%29&adgroup=${p3}%20%28${p4}%29&creative=${p5}%20%28${p6}%29&fbclid=${fbclid}&fbpid=${fbpid}&fb_pixel_id=${fb_pixel_id}&fb_access_token=${fb_access_token}&redirect=https%3A%2F%2Fsupertochance.com%2Fdownloads%2F20241021-1548-TanChat-product-release.apk`;
  // }
  const [downloaded, setDownloaded] = useState(false);
  // 下载 APK 函数（仅在首次点击 Link 时触发）
  const handleFirstDownload = () => {
    if (!downloaded) {
      // 动态生成带有时间戳的 URL 防止缓存
      const apkPath = 'https://supertochance.com/downloads/20241021-1548-TanChat-product-release.apk'; // 替换为实际 APK 文件路径
      const timestamp = new Date().getTime();
      // const downloadUrl = `${apkPath}?t=${timestamp}`;
      const downloadUrl = `${apkPath}`;

      // 触发下载
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = '20241021-1548-TanChat-product-release.apk'; // 可以自定义下载的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // 设置状态为已下载
      setDownloaded(true);

      // fbq('track', 'Purchase', {
      //   value: 30.0,
      //   currency: 'USD',
      // });
    }
  };

  // 强制重新下载函数（通过按钮点击）
  const handleForceDownload = () => {
    const apkPath = 'https://supertochance.com/downloads/20241021-1548-TanChat-product-release.apk'; // 替换为实际 APK 文件路径
    const timestamp = new Date().getTime();
    // const downloadUrl = `${apkPath}?t=${timestamp}`;
    const downloadUrl = `${apkPath}`;

    // 触发下载
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = '20241021-1548-TanChat-product-release.apk';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handTrackingClick = () => {
    window.location.href = catLinkUrl;
  };

  // 获取 URL 中的 fbclid 参数
  function getFbclid(key) {
    const params = new URLSearchParams(window.location.search);
    return params.get(key);
  }

  return (
    <div>
      <Link
        href={catLinkUrl}
        // target="_blank"
        // onClick={(e) => {
        //   e.preventDefault(); // 阻止默认跳转行为
        //   handleFirstDownload();
        // }}
        // download
        sx={{
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        {/* 顶部导航栏 */}
        <AppHeader />

        <Container sx={{ mb: 8, paddingLeft: '24px', paddingRight: '24px' }}>
          {/* 应用信息 */}
          <AppInfo handTrackingClick={handTrackingClick} catLinkUrl={catLinkUrl} />

          {/* 应用截图轮播 */}
          <AppScreenshots />

          {/* 应用介绍 */}
          <AppDescription />

          {/* Update Info */}
          <Box mt={2}>
            <Typography variant="subtitle1" fontWeight="bold">
              Şu tarihte güncellendi
            </Typography>
            <Typography color="textSecondary">11 Ekim 2024</Typography>
          </Box>

          {/* Tags */}
          <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
            {['sohbetler', 'randevu', 'sesli posta odası', 'Oyna', 'sevişmek', 'çizginin altında'].map((tag) => (
              <Chip key={tag} label={tag} variant="outlined" />
            ))}
          </Box>

          {/* Events & Offers */}
          <Box mt={2}>
            <Typography variant="subtitle1" fontWeight="bold">
              Etkinlikler & Teklifler
            </Typography>
            {/*<Box
              component="img"
              src={otherImg1}
              alt="Event"
              sx={{ width: '100%', maxWidth: '550px', borderRadius: 2, marginTop: 1, mt: 2, mb: 2 }}
            />*/}
            {/* 视频卡片 */}
            <Card sx={{ maxWidth: '100%', mt: 1, mb: 2 }}>
              <CardMedia
                component="video"
                src={video} // 替换为你的视频URL
                autoPlay
                loop
                muted
                controls={false} // 可根据需要控制是否显示视频控件
                sx={{
                  height: 200, // 设置视频高度
                  objectFit: 'cover', // 保持视频的宽高比，并填充整个容器
                  border: 'none', // 移除黑色边框
                }}
              />
            </Card>
          </Box>

          {/* Data Safety */}
          <AppDataSafety />

          {/* Reviews */}
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Değerlendirmeler ve Yorumlar
            </Typography>
            <Typography color="textSecondary" sx={{ display: 'inline-flex', alignItems: 'center' }}>
              Derecelendirmeler ve yorumlar doğrulanır
              <ErrorOutlineOutlined fontSize="small" sx={{ width: '12px', height: '12px', ml: 0.5 }} />
            </Typography>
          </Box>

          <AppReviewCard />

          {/* 应用支持 */}
          {/* <AppSupport /> */}

          {/* 类似应用 */}
          <AppFooter />
          {/* <SimilarApps /> */}
        </Container>
        {/* 底部导航栏 */}
        <AppBottomNav></AppBottomNav>
      </Link>
    </div>
  );
};

export default AppPage;
